p > a {
    color: rgb(30, 64, 175);
    text-decoration: underline;
}

.news-content > h1 {
    font-size: 1.875rem;
    line-height: 2.25rem;
}

.news-content > h2 {
    font-size: 1.5rem;
    line-height: 2rem;
}

.news-content > ul > li {
    list-style-type: disc;
    margin-left: 14px;
}

.news-content > p:has(img) {
    width: 100%;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    margin-bottom: 5px;
}

.news-content > p > img {
    width: 1%;
    height: auto;
    display: block;
    flex: 1 1 calc(33.333% - 5px);
    object-fit: cover;
}

/* Si l'image est seule, elle prend toute la largeur */
.news-content > p > img:only-child {
    width: 100%;
}

/* Si il y a exactement deux images */
.news-content > p > img:first-child:nth-last-child(2),
.news-content > p > img:first-child:nth-last-child(2) ~ img {
    width: calc(50% - 5px);
}

.news-content > p > img:first-child:nth-last-child(2) ~ img {
    width: calc(50% - 5px);
}

.idea-box-container {
    perspective: 500px;
    transform-style: preserve-3d;
    transform: translateY(-0px);
}

.idea-box-top {
    height: 130px;
    transition: transform 0.3s ease;
    transform: translateZ(-50px) translateY(-44px) rotateX(85deg);
}
.idea-box-container:hover > .idea-box-top {
    transform: translateZ(-45px) translateY(-54px) rotateX(75deg);
}

.idea-box-front {
    transform: translateY(-105px);
    transition: transform 0.3s ease;
}
.idea-box-container:hover .idea-box-front {
    transform: translateY(-105px) rotateX(-15deg);
}

.idea-box-paper {
    opacity: 0;
    height: 80px;
    transform: translateY(-60px);
    transform-origin: 0 100%;
    transition: all 0.3s ease;
}
.idea-box-container:hover .idea-box-paper {
    opacity: 1;
    height: 40px;
    transform: translateY(-21px);
}

.Click-here {
    cursor: pointer;
    background-image: linear-gradient(190deg, #f83600 0%, #fee140 100%);
    color: #fff;
    width: 180px;
    text-align: center;
    font-size:16px;
    padding: 18px 0;
    margin: 0 auto;
    transition:background-image 3s ease-in-out;
}
.Click-here:hover{
    transition:background-image 3s ease-in-out;
    background-image: linear-gradient(90deg, #fee140 0%, #f83600 100%);
}
.custom-model-main {
    text-align: center;
    overflow: hidden;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    -webkit-overflow-scrolling: touch;
    outline: 0;
    -webkit-transition: opacity 0.15s linear, z-index 0.15;
    -o-transition: opacity 0.15s linear, z-index 0.15;
    transition: opacity 0.15s linear, z-index 0.15;
    z-index: -1;
    overflow-x: hidden;
    overflow-y: auto;
}

.model-open {
    z-index: 99999;
    opacity: 1;
    overflow: hidden;
}
.custom-model-inner {
    -webkit-transform: translate(0, -25%);
    -ms-transform: translate(0, -25%);
    transform: translate(0, -25%);
    -webkit-transition: -webkit-transform 0.3s ease-out;
    -o-transition: -o-transform 0.3s ease-out;
    transition: -webkit-transform 0.3s ease-out;
    -o-transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
    display: inline-block;
    vertical-align: middle;
    margin: 30px auto;
    max-width: 97%;
}
.custom-model-wrap {
    display: block;
    position: relative;
    background-color: #fff;
    border-radius: 6px;
    padding: 10px;
}
.model-open .custom-model-inner {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0);
    position: relative;
    z-index: 999;
}
.model-open .bg-overlay {
    background: rgba(0, 0, 0, 0.6);
    z-index: 99;
}
.bg-overlay {
    background: rgba(0, 0, 0, 0);
    height: 100vh;
    width: 100%;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    -webkit-transition: background 0.15s linear;
    -o-transition: background 0.15s linear;
    transition: background 0.15s linear;
}
.close-btn {
    position: absolute;
    right: 0;
    top: -40px;
    cursor: pointer;
    z-index: 99;
    font-size: 30px;
    color: #fff;
}

@media screen and (min-width:800px){
    .custom-model-main:before {
        content: "";
        display: inline-block;
        height: auto;
        vertical-align: middle;
        margin-right: -0px;
        height: 100%;
    }
}
@media screen and (max-width:799px){
    .custom-model-inner{margin-top: 45px;}
}

.card-container {
    position: relative;
    perspective: 1000px;
    width: 10.75em;
    height: 15.5em;
    cursor: pointer;
}

.card-container .card {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    transition: transform 0.6s;
    transform-style: preserve-3d;
}

.card-container .back {
    transform: rotateY(180deg) scale(1.5);
}

.card-container.flipped .back {
    transform: rotateY(0deg) scale(1);
    opacity: 0;
}

.card-container.flipped .front {
    transform: rotateY(-180deg) scale(1);
    opacity: 0;
}

.card-container.disappear .front {
    transform: translateY(-400%);
}

.card-container .front {
    transform: rotateY(0deg) scale(1.5);
}
